import Vue from "vue";

Vue.filter("thousands", (value: number) => {
  if (!value || typeof value !== "number") return value;

  return value.toLocaleString();
});

Vue.filter("percent", (value: number) => {
  if (value == 0) 
    return "0%";

  if (!value || typeof value !== "number") 
    return value;  

  return `${Number(Math.round(`${value}e+2` as any) + "e-2")}%`;
});
