import config, { Config } from "@/config";

import AxiosService from "./axios-service";

export type EmbededConfig = {
  Type: string;
  EmbedReport: EmbedReport[];
  EmbedToken: EmbedToken;
}

export type EmbedReport = {
  ReportId: string;
  ReportName: string;
  EmbedUrl: string;
}

export type EmbedToken = {
  token: string;
  tokenId: string;
  expiration: string;
}

export default class AnalyticsPowerBIService {
  axios: AxiosService;
  config: Config;

  constructor(axios: AxiosService, config: Config) {
    this.axios = axios;
    this.config = config;
  }

  async getDashboard(): Promise<EmbededConfig> {
    const result = await this.axios.get(`${config.analyticsApi}/PowerBi/Dashboard`)
    return JSON.parse(result)
  }

  async getDashboardById(reportId: string): Promise<EmbededConfig> {
    const result = await this.axios.get(`${config.analyticsApi}/PowerBi/Dashboard/${reportId}`)
    return JSON.parse(result)
  }

}
