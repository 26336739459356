import store, { hasState } from '@/store';
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';

export declare interface ApiErrorState {
  down: boolean;
  message?: string;
  date?: Date;
}

const shouldLoadState = hasState('app-state');

@Module({
  dynamic: true,
  store,
  name: 'app-state',
  preserveState: shouldLoadState
})

export default class AppState extends VuexModule {
  drawer: boolean | null = null;

  apiError: ApiErrorState = { down: false };

  title = 'Hazcheck Detect';

  get apiFault() {
    return Boolean(this.apiError.down || this.apiError.message);
  }

  @Mutation
  toggleDrawer() {
    this.drawer = !this.drawer;
  }

  @Mutation
  setDrawer(payload: boolean | null) {
    this.drawer = payload;
  }

  @Mutation
  setApiError(payload: ApiErrorState) {
    this.apiError = {
      down: payload?.down,
      message: payload?.message,
      date: new Date(),
    };
  }

  @Mutation
  setTitle(payload: string) {
    const newTitle = `Hazcheck Detect${payload ? `: ${payload}` : ''}`;

    document.title = newTitle;
    this.title = newTitle;
  }

  @Action({ commit: 'setApiError' })
  resetApiError(): ApiErrorState {
    return { down: false };
  }
}
