import { Config } from '@/config';

import AxiosService from './axios-service';
import AuthService from './auth-service';
import AnalyticsService from './analytics-service';
import ConfigurationService from './configuration-service';
import AnalyticsPowerBIService from './analytics-powerbi-service';

type Services = {
  AuthService: AuthService;
  AnalyticsService: AnalyticsService;
  ConfigurationService: ConfigurationService;
}

const services: Services = {} as any;

export function loadServices(config: Config) {
  const authService = new AuthService(config);
  const axiosService = new AxiosService(authService);

  Object.assign(services, {
    AuthService: authService,
    AnalyticsService: new AnalyticsService(axiosService, config),
    AnalyticsPowerBIService: new AnalyticsPowerBIService(axiosService, config),
    ConfigurationService: new ConfigurationService(axiosService)
  } as any);

  return services;
};

export default services;
