import AxiosService from "./axios-service";
import config from "@/config";

export interface AllStatusesConfig {
  statuses: SingleStatusConfig[];
}

export interface SingleStatusConfig {
  id: number;
  mandatoryNotes: boolean;
  metadata: Metadata[];
}

export interface Metadata {
  type: string;
  name: string;
  isRequired: boolean;
  values: MetadataValue[];
}

export interface MetadataValue {
  displayName: string;
  value: string;
}

export default class ConfigurationService {
  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async getStatusMetadataConfig() {

    const uri = `${config.caseManagementApi}/configuration/status-metadata`;

    const response = await this.axios.get(uri);
      
    return JSON.parse(response.json)
  }
}
