import axios, { AxiosError, AxiosResponse } from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';

import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';
import { WillHandle, CustomErrorMessageHandle } from '@/services/axios-service.d';

const appState = getModule(AppState);

function getErrorReason(response?: AxiosResponse<any>) {
  if (response === undefined) return undefined;

  if (response.status === 400) return 'An error occurred submitting your request.';
  if (response.status === 401) return 'Please log in and try again.';
  if (response.status === 403) return 'You do not have access to perform this operation.';
  if (response.status === 500) return 'The API encountered an error processing your request.';

  return `Something went wrong processing your request. ${response.statusText}`;
}

axios.interceptors.response.use(
  (response) => {
    appState.resetApiError();

    return Promise.resolve(response);
  },
  (error: AxiosError) => {
    if (axios.isCancel(error))
      return Promise.reject(error);

    const response = error.response;
    const statusCodeHandler = (error.config as WillHandle & CustomErrorMessageHandle);
    if (statusCodeHandler
      && response
      && statusCodeHandler.willHandle
      && statusCodeHandler.willHandle(response.status)
      )
    {
      return Promise.reject(error);
    }

    const isApiDown = error.response === undefined;
    let errorReason = getErrorReason(error.response);

    if(response && statusCodeHandler.getCustomErrorMessage)
    {
      const message = statusCodeHandler.getCustomErrorMessage(response);
      if(message)
        errorReason = message;
    }

    appState.setApiError({
      down: isApiDown,
      message: errorReason,
    });

    return Promise.reject(error);
  },
);

Vue.use(VueAxios, axios);
