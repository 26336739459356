import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import Vue from "vue";

dayjs.extend(relativeTime);
dayjs.extend(utc);

Vue.filter("ago", (value: string) => value && dayjs.utc(value).fromNow());

Vue.filter(
  "date",
  (value: string) =>
    value &&
    dayjs
      .utc(value)
      .local()
      .format("YYYY-MM-DD")
);

Vue.filter(
  "time",
  (value: string) =>
    value &&
    dayjs
      .utc(value)
      .local()
      .format("HH:mm:ss")
);

Vue.filter(
  "datetime",
  (value: string) =>
    value &&
    dayjs
      .utc(value)
      .local()
      .format("YYYY-MM-DD HH:mm:ss")
);
