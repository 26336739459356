import { getHost } from './src-helpers';

export type Config = {
  analyticsApi: string;
  hazcheckDetectUrl: string;
  caseManagementUrl: string;
  caseManagementApi: string;
  authentication: {
    keycloakUrl: string;
    realm: string;
    frontendClientId: string;
    backendAudience: string;
  };
  showCaseManagement: boolean;
  caseStatus: { value: number; name: string }[];
  navigation: any[];
}

const config = {
  authentication: {},
  showCaseManagement: false,
  caseStatus: [],
  navigation: []
} as unknown as Config;

function lowerCaseFirstLetter([first, ...rest]: string): string {
  return [first.toLowerCase(), ...rest].join('');
}

function mapKeys(obj: any, map: (key: string) => string): any {
  if (obj instanceof Array) {
    return obj.map(x => mapKeys(x, map));
  }

  if(!(obj instanceof Object)){
    return obj;
  }

  const keys = Object.keys(obj);

  return keys.reduce(
    (acc, key) => {
      const val = obj[key];
      return {
        ...acc,
        [map(key)]: typeof (val) === 'object' ? mapKeys(val, map) : val,
      };
    },
    {} as any,
  );
}


export async function loadConfig(): Promise<Config> {
  let envConfigFile = 'js/config-env.json';
  const localConfigFile = 'js/config.json.local';
  let fetchLocalConfigFile = Promise.resolve({} as any);

  if (window) {
    const query = new URLSearchParams(window.location.search);
    envConfigFile = query.get("config") || envConfigFile;

    const host = getHost();
    if (host =='localhost' || host.indexOf('localhost') == 0) {
      fetchLocalConfigFile = fetch(localConfigFile).then(response => response.json());
    }
  }

  return await Promise.all([
    fetch('js/config.json'),
    fetch(envConfigFile),
    fetchLocalConfigFile
  ])
    .then(([baseResponse, envResponse, localResponse]) => Promise.all([
      baseResponse.json(),
      envResponse.json(),
      localResponse
    ]))
    .then(([baseJson, envJson, localJson]) => ({ ...baseJson, ...envJson, ...localJson }))
    .then((json) => mapKeys(json, lowerCaseFirstLetter))
    .then((json) => Object.assign(config, json)) as Promise<Config>;
}

export default config;
