import config, { Config } from "@/config";

import AxiosService from "./axios-service";

export type ScreeningStats = {
  Timestamp: Date;
  Screenings: number;
  ["Unique Bookings"]: number;
  ["With hits"]: number;
};

export type ScreeningStatsResponse = {
  Timestamp: string | undefined;
  Screenings: number;
  ["Unique Bookings"]: number;
  ["With hits"]: number;
};

export type RulesStats = {
  Date: Date;
  RuleId: string;
  RuleName: string;
  LibraryId: string;
  LibraryName: string;
  Count: number;
  FirstInstanceOfBookingReference: number;
};

export type RulesStatsResponse = {
  Date: string;
  RuleId: string;
  RuleName: string;
  LibraryId: string;
  LibraryName: string;
  Count: number;
  FirstInstanceOfBookingReference: number;
};

export type StatusMetadata = {
  Key: string;
  Value: string;
  DisplayValue: string;
}

export type StatusStats = {
  Time: Date;
  Status: number;
  Metadata?: StatusMetadata[];
  Total: number;
}

export type StatusStatsResponse = {
  Time: string;
  Status: number;
  Metadata: string;
  Total: number;
}

export type AnalyticsData<T> = {
  data: T[];
  lastUpdated: Date;
};

type AnalyticsSummary = { parts: string[]; lastUpdated: Date };

type Strings<T> = {
  [Prop in keyof T]: T[Prop];
};

export default class CaseService {
  axios: AxiosService;
  config: Config;

  constructor(axios: AxiosService, config: Config) {
    this.axios = axios;
    this.config = config;
  }

  async readScreenings(): Promise<AnalyticsData<ScreeningStats>> {
    const uri = `${config.analyticsApi}/analytics/screenings`;

    return await this.getAnalyticsData<ScreeningStatsResponse, ScreeningStats>(
      uri,
      x => Boolean(x.Timestamp),
      x => ({ ...x, Timestamp: new Date(x.Timestamp!) })
    );
  }

  async readRules(): Promise<AnalyticsData<RulesStats>> {
    const uri = `${config.analyticsApi}/analytics/rules`;

    return await this.getAnalyticsData<RulesStatsResponse, RulesStats>(
      uri,
      x => Boolean(x.Date),
      x => ({ ...x, Date: new Date(x.Date!) })
    );
  }

  async readCasesByStatus(): Promise<AnalyticsData<StatusStats>> {
    const uri = `${config.analyticsApi}/analytics/current-status-on-hour`;

    return await this.getAnalyticsData<StatusStatsResponse, StatusStats>(
      uri,
      x => Boolean(x.Time),
      x => ({ ...x, Time: new Date(x.Time!), Metadata: []})
    )
  }

  async readActivity(): Promise<AnalyticsData<StatusStats>> {
    const uri = `${config.analyticsApi}/analytics/status-change-by-hour`;

    return await this.getAnalyticsData<StatusStatsResponse, StatusStats>(
      uri,
      x => Boolean(x.Time),
      x => ({ ...x, Time: new Date(x.Time!), Metadata: []})
    )
  }

  async readCasesByMetadata(): Promise<AnalyticsData<StatusStats>> {
    const uri = `${config.analyticsApi}/analytics/metadata-rollup-by-hour`;

    return await this.getAnalyticsData<StatusStatsResponse, StatusStats>(
      uri,
      x => Boolean(x.Time),
      x => ({ ...x, Time: new Date(x.Time!), Metadata: JSON.parse(x.Metadata) })
    )
  }

  async readCasesByMetadataLatest(): Promise<AnalyticsData<StatusStats>> {
    const uri = `${config.analyticsApi}/analytics/metadata-rollup-by-hour-latest`;

    return await this.getAnalyticsData<StatusStatsResponse, StatusStats>(
      uri,
      x => Boolean(x.Time),
      x => ({ ...x, Time: new Date(x.Time!), Metadata: JSON.parse(x.Metadata) })
    )
  }

  private async getAnalyticsData<TResponse, TData>(
    uri: string,
    filter: (x: TResponse) => boolean,
    map: (x: TResponse) => TData
  ): Promise<AnalyticsData<TData>> {
    const summary = await (this.axios.get(uri) as Promise<AnalyticsSummary>);

    const requests = summary.parts.map(
      part => this.axios.get(
        `${config.analyticsApi}${part}`,
        {
          transformResponse(response: string) {
            return response.split("\n")
              .flatMap(line => {
                try {
                  return [JSON.parse(line)];
                } catch {
                  return [];
                }
              })
          }
        }
      ) as Promise<TResponse[]>
    );

    const allData = await Promise.all(requests);

    const data = allData
      .flatMap(x => x)
      .filter(filter)
      .map(map);

    return { data, lastUpdated: new Date(summary.lastUpdated) };
  }
}
