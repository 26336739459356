import Vue from "vue";
import plugins from "@/plugins/index";
import { loadConfig } from "@/config";
import { loadServices } from "@/services/index";
import "@/filters/index";

import App from "./App.vue";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-dayjs";
import "./registerServiceWorker";

import { buildRouter } from './router';
import store from "./store";

Chart.register(...registerables);

Vue.config.productionTip = false;

(async function () {
  const config = await loadConfig();
  const services = await loadServices(config);
  await services.AuthService.init();
  const router = await buildRouter(services.AuthService, config);

  const vue = new Vue({
    router,
    store,
    ...plugins,
    provide: services,
    render: (h) => h(App),
  });

  (window as any).$$vue = vue;

  vue.$mount('#app');
})();
